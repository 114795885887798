import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

// import styled from "@emotion/styled"
import { css } from "@emotion/react"

const ItemsInBox = ({ filteredProducts }) => {
  
  return (
    <div className="yourbox__itemsContainer">
      {filteredProducts.map((productRaw, index) => {
        const { node: product } = productRaw

        return (
          <div
            key={product.id}
            className="u-margin-top-small yourbox__itemCard"
          >
            {/* ****************** TOP OF CARD! ****************** */}
            <div
              css={css`
                background: url(${product.naturePhoto.url});
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
              `}
              className="yourbox__itemCard__top"
            >
              <div className="yourbox__itemCard__top__packageImg">
                <Img fluid={product.photos[1].fluid} />
              </div>
            </div>

            {/* ****************** BOTTOM OF CARD! ****************** */}
            <div className="yourbox__itemCard__bottom">
              <div className="yourbox__itemCard__bottom__header u-margin-bottom-tiny">
                <div className="yourbox__itemCard__bottom__header__left">
                  <h1>{product.name}</h1>
                  <p className="yourbox__itemCard__bottom__header__ingredient">{product.ingredientAndDosage}</p>
                </div>

                <div className="yourbox__itemCard__bottom__header__right">
                  <p className="yourbox__itemCard__bottom__header__ingestion">{product.modeOfIngestion}</p>
                </div>
              </div>

              <hr className="u-margin-bottom-tiny" />
              
              <div className="yourbox__itemCard__bottom__benefits u-margin-bottom-tiny">
                <p>{product.supplementLink[0].benefits}</p>
                <Link to={`/learn/${product.supplementLink[0].slug}`}>Learn more</Link>
              </div>
              <hr className="u-margin-bottom-tiny" />

              <div className="yourbox__itemCard__bottom__cartDetails">
                <p>1 week supply</p>
                <h3>${product.price}</h3>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ItemsInBox
