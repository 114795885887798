import React from 'react'
import { Link } from "gatsby"
import Img from "gatsby-image"

import Button from "../buttons/button"
import SectionLine from "../sectionline"
import ItemsInBox from "../yourbox/itemsinbox"

import oopsIcon from "../../../data/icons/oops.svg"


const CreateBox = ({ isWindow, filteredProducts, data }) => {
    if (isWindow() && filteredProducts.length > 0) {
      return (
        <section className="u-standard-margins yourbox">
          <div className="u-padding-top-medium">
            <div className="yourbox__header">Your Personalized</div>
            <div className="yourbox__header__bigger u-margin-bottom-small">
              Stress Relief Box
            </div>
            <SectionLine />
            <div className="yourbox__discoveryBox">
              <div className="yourbox__discoveryBox__imageBox">
                <Img fluid={data.boxImage.childImageSharp.fluid} />
              </div>
              <p className="yourbox__discoveryBox__text">
                A discovery box tailored to your unique needs.
              </p>
            </div>

            <h1 className="u-margin-top-medium">IN YOUR BOX</h1>

            <ItemsInBox filteredProducts={filteredProducts} />

            <div className="u-padding-top-bottom-big u-center-text">
              <button
                className="button__primary"
                onClick={() => {
                  window.Snipcart.api.theme.cart.open()
                }}
              >
                View cart
              </button>
              <br />
              <br />
              <Link to="/shop" className="yourbox__link">
                Keep Shopping &#8594;
              </Link>

              {/* <button 
              className="button__primary" 
              onClick = {() => {
                console.log(filteredProducts);
              }}
            >
              DEBUG PRINT VAR
            </button> */}
            </div>
          </div>
        </section>
      )
    } else {
      // navigate("/shop")
      return (
        <div className="u-standard-margins yourbox__errorscreen">
          <img
            src={oopsIcon}
            className="yourbox__errorscreen__icon"
            alt="cart icon"
          />
          <h1>
            Oops, either you haven't taken the quiz yet or something went wrong.
            <br />
            Please try taking the quiz again.
          </h1>
          <Button type="primary" text="Try the quiz" link="quiz" />
        </div>
      )
    }
  }

  export default CreateBox;