import React from "react"

const SectionLine = () => {
    return (
        <hr 
            className="sectionline"

        />
    )
}

export default SectionLine;