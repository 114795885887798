import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import CreateBox from "../components/yourbox/createbox.jsx"

import isWindow from "../functions/checkForWindow"
import { addCart } from "../components/yourbox/addCartFunction"

import QuizHeader from "../components/quiz/quizheader"
import Layout from "../components/layout"

const YourBox = props => {
  
  const [filteredState, setFilteredState] = useState([]);
  const [onlyOnce, setOnlyOnce] = useState(1);

  //destructure graphql data
  const {
    data: {
      productsCMS: { edges: productData },
    },
  } = props;
  let filteredProducts = []

  //prepare resultsData into array
  if (props.location.state) {
    const resultsData = Object.values(props.location.state)
    resultsData.pop()
    filteredProducts = productData.filter(el => {
      return resultsData.indexOf(el.node.label1) > -1
    })
  }

  useEffect(() => {
    //feels very janky couldnt stop infinite loop here so added this onlyOnce state
    //to force only one execution
    if (onlyOnce === 1) {
      if (isWindow() && filteredProducts.length > 0) addCart(filteredProducts)
      //WORK AROUND need to set the filtered products in the state so does not get wiped!
      //when user opens cart from snipcart
      setFilteredState(filteredProducts);
      setOnlyOnce(2);
    }
  }, [filteredProducts, onlyOnce])

  return (
    <Layout yourboxPage={true} >
      <QuizHeader />

      <CreateBox
        isWindow={isWindow}
        filteredProducts={filteredState}
        data={props.data}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    boxImage: file(relativePath: { eq: "quiz/discoveryBox.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productsCMS: allDatoCmsProduct {
      edges {
        node {
          description
          id
          label1
          label2
          label3
          modeOfIngestion
          ingredientAndDosage
          name
          supplementLink {
            benefits
            slug
          }
          price
          slug
          photos {
            alt
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
          naturePhoto {
            url
            alt
          }
        }
      }
    }
  }
`

export default YourBox
