export const addCart = async (filteredProducts) => {
    try {
      if (filteredProducts.length > 0) {
        await filteredProducts.forEach(product => {
          console.log(product.node.name);
          window.Snipcart.api.cart.items.add({
            id: product.node.id,
            name: product.node.name,
            price: product.node.price,
            url: `https://modest-curran-c90323.netlify.app/shop/${product.node.slug}`,
            quantity: 1,
          })
        })
      } else {
        return
      }
    } catch (e) {
      console.log(e)
    }
  }